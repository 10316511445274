<template>
	<div>
		<b-modal ref="show-auth" 
				v-model="localShowAuth"
				@hide="emitCloseEvent" 
				centered hide-footer hide-header 
				@show="resetForm">
			<div class="position-relative ">
				<b-tabs v-if="!isThereRequestToConfirmMail" content-class="mt-3">
					<b-tab title="Вход" active>
						<b-form @submit="onSubmitAuth" style="padding-left: 20px; padding-right: 20px;"> 
							<b-form-input
								id="email-auth"
								v-model="formAuth.email"
								type="email"
								placeholder="Почта"
								required
								class="mb-2"
								:disabled="isShowSuccessRegAlert || isShowSuccessAuthAlert"
							></b-form-input>
							<b-form-input 
								id="password-auth" 
								v-model="formAuth.password"
								type="password" 
								placeholder="Пароль"
								required
								class="mb-3"
								:disabled="isShowSuccessRegAlert || isShowSuccessAuthAlert"
							></b-form-input>
							<b-button type="submit" variant="primary" style="width: 100%;" :disabled="isShowSuccessRegAlert || isShowSuccessAuthAlert">Войти</b-button>
							<div class="text-center">
								<b-link class="mt-2 password-reset-button" @click="localShowAuth = false; resetPasswordModal = true;">
									Забыли пароль?
								</b-link>
							</div>
							
							<b-alert v-model="isShowDangerAuthAlert" variant="danger" class="mt-3">
								Ошибка авторизации. Пожалуйста, проверьте свои учетные данные и повторите попытку.
							</b-alert>
							<b-alert v-model="isShowSuccessAuthAlert" variant="success" class="mt-3">
								Вы авторизованы!
							</b-alert>

							<div class="text-center" style="margin-top: 20px; font-size: 12px; padding-left: 10px; padding-right: 10px;">
								<p>
									Продолжая, вы подтверждаете согласие с 
									<a href="/terms-of-service" style="margin-left: 0; cursor: pointer;" class="text-reset">пользовательским соглашением и политикой конфиденциальности</a>
								</p>
							</div>
						</b-form>
					</b-tab>
					<b-tab title="Регистрация">
						<b-form @submit="onSubmitReg" style="padding-left: 20px; padding-right: 20px;">
							<b-form-input 
								id="name-reg"
								v-model="formReg.name" 
								placeholder="ФИО (Иванов Иван Иванович)"
								pattern="^(?=.{2,30}$)(?! )[а-яА-ЯёЁ]{2,}(?: [а-яА-ЯёЁ]{2,})*$"
								required
								class="mb-2"
								:disabled="isShowSuccessRegAlert || isShowSuccessAuthAlert"
							></b-form-input>
							<b-form-input 
								id="phoneNumber-reg"
								v-model="formReg.phoneNumber" 
								placeholder="Номер телефона (+79ХХХХХХХХХ)"
								pattern="^(\+7|8)9\d{2}\d{3}\d{2}\d{2}$"
								required
								class="mb-2"
								:disabled="isShowSuccessRegAlert || isShowSuccessAuthAlert"
							></b-form-input>
							<b-form-input 
								id="email-reg"
								v-model="formReg.email" 
								type="email"
								placeholder="Почта (например, ivan@bk.ru)"
								required
								class="mb-2"
								:disabled="isShowSuccessRegAlert || isShowSuccessAuthAlert"
							></b-form-input>
							<b-form-input 
								id="password-reg"
								v-model="formReg.password" 
								type="password"
								placeholder="Пароль (например, Ivanov123!)"
								required
								class="mb-3"
								:disabled="isShowSuccessRegAlert || isShowSuccessAuthAlert"
								pattern="^(?=.*[A-Za-z])(?=.*\d)\S{8,}$"
							></b-form-input>
							<b-button type="submit" variant="primary" style="width: 100%;" :disabled="isShowSuccessRegAlert || isShowSuccessAuthAlert">Зарегистрироваться</b-button>

							<b-alert v-model="isShowDangerRegAlert" variant="danger" class="mt-3">
								Данный email уже зарегистрирован.
							</b-alert>
							<b-alert v-model="isShowSuccessRegAlert" variant="success" class="mt-3">
								Вы зарегистрированы!
							</b-alert>

							<div class="text-center" style="margin-top: 20px; font-size: 12px; padding-left: 10px; padding-right: 10px;">
								<p>
									Продолжая, вы подтверждаете согласие с 
									<a href="/terms-of-service" style="margin-left: 0; cursor: pointer;" class="text-reset">пользовательским соглашением и политикой конфиденциальности</a>
								</p>
							</div>
						</b-form>
					</b-tab>
				</b-tabs>
				<div v-else>
					<h5 style="padding-top: 5px;">Подтверждение аккаунта</h5>
					<p style="margin-bottom: 10px; padding-top: 10px;" class="text-center">
						Письмо для подтверждения аккаунта было отправлено на Вашу почту. Срок действия ссылки для подтверждения аккаунта 2 часа.
					</p>
					<div v-if="timeToResend === '00:00'" style="display: flex;  justify-content: center;">
						<b-button variant="primary" size="sm" @click="resendConfirmationEmail">
							Отправить повторно
						</b-button>
					</div>
					<p v-else class="text-center" style="color: rgb(31, 31, 154); margin-top: 5px; margin-bottom: 0px;">Повторная отправка письма возможна через {{ timeToResend }}</p>
				</div>
				<b-button variant="outline-danger" size="sm" @click="localShowAuth = false" class="position-absolute top-0 end-0 mt-1 mr-1"> <!-- @click="close()"-->
					<b-icon icon="x"></b-icon>
				</b-button>
			</div>
			<ToastView ref="toastRef" />
		</b-modal> 
		<ToastView ref="toastRefMain" />
		<b-modal v-model="resetPasswordModal" centered hide-footer hide-header @hide="closeResetPasswordModal">
			<div class="position-relative " style="padding-left: 20px; padding-right: 20px;">
				<h4 style="margin-bottom: 16px;">Восстановление пароля</h4>
				<p>Введите Ваш email, используемый для входа. <br>Мы вышлем письмо с инструкцией</p>
				<b-form @submit="onSubmitResetPassword" > 
					<b-form-input
						id="email-reset-password"
						v-model="formResetPassword.email"
						type="email"
						placeholder="Почта"
						required
						class="mb-2"
					></b-form-input>
					<b-button type="submit" variant="primary" style="width: 100%;">Восстановить</b-button>
				</b-form>
				<b-button variant="outline-danger" size="sm" @click="resetPasswordModal = false;" class="position-absolute top-0 end-0 mt-1 mr-1"> <!-- @click="close()"-->
					<b-icon icon="x"></b-icon>
				</b-button>
			</div>
			<ToastView ref="toastRef2" />
		</b-modal>
	</div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '../../config.js';
import ToastView from "./ToastView.vue";

export default {
	components: {
        ToastView,
    },
	props: {
		showAuth: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			localShowAuth: false,
			formAuth: {
				email: '',
				password: '',
			},
			formReg: {
				name: '',
				phoneNumber: '',
				email: '',
				password: '',
			},
			isShowDangerAuthAlert: false,
			isShowDangerRegAlert: false,
			isShowSuccessAuthAlert: false,
			isShowSuccessRegAlert: false,

			isThereRequestToConfirmMail: false, // Проверка на наличие запроса на подтверждение почты
			timeToResend: '00:00', // Время до повторной отправки
			timer: null, // Таймер для повторной отправки
			modeSubmit: 'auth', // Режим отправки данных (auth - авторизация, reg - регистрация)
			
			resetPasswordModal: false,
			formResetPassword: {
				email: '',
			},
		};
	},
	watch: {
    showAuth(value) {
      this.localShowAuth = value; // Для отслеживания изменений значения пропса showAuth
    },
  },
  beforeDestroy() {
	clearInterval(this.timer);
  },
  methods: {
		startTimer() {
			const startTime = new Date();
			let secondsLeft = 300;
			this.timeToResend = '05:00';
			this.timer = setInterval(() => {
				const currentTime = new Date();
				const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
				secondsLeft = 300 - elapsedSeconds;
				if (secondsLeft < 0) {
					clearInterval(this.timer);
					this.timeToResend = '00:00';
				} else {
					const minutes = Math.floor(secondsLeft / 60).toString().padStart(2, '0');
					const seconds = (secondsLeft % 60).toString().padStart(2, '0');
					this.timeToResend = `${minutes}:${seconds}`;
				}
			}, 1000);
		},
		// Повторная отправка запроса на подтверждение аккаунта по почте
		resendConfirmationEmail() {
			// Если режим отправки данных - авторизация, то берем почту из формы авторизации, иначе из формы регистрации
			const email = this.modeSubmit === 'auth' ? this.formAuth.email : this.formReg.email;
			const path = `${API_URL}/users/confirm_email/${email}`;
			const base_url = window.location.origin;
			axios.post(path, null, { headers: { 'Client-Url': base_url } }).then((response) => {
				this.$refs.toastRef.showCustomToast('Повторное письмо для подтверждения аккаунта было отправлено на Вашу почту', 'success', 7000);
				// Запуск таймера для повторной отправки письма
				this.startTimer();
			}).catch((error) => {
				try {
					if (error.response.status === 400) {
						this.$refs.toastRef.showCustomToast('Пользователь с данной почтой уже подтвержден', 'danger', 5000);
						// Перейти в личный кабинет
						window.location.href = '/account';
					}
					else {
						this.$refs.toastRef.showCustomToast('Ошибка отправки письма для подтверждения аккаунта', 'danger', 5000);
					}
				}
				catch {
					this.$refs.toastRef.showCustomToast('Ошибка отправки письма для подтверждения аккаунта', 'danger', 5000);
				}
			});
		},
		// termsOfService() {
        //     const path = `${API_URL}/terms-of-service`;
        //     axios.get(path, { responseType: 'blob' })
        //     .then(response => {
        //         const url = window.URL.createObjectURL(new Blob([response.data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'Пользовательское соглашение и политика конфиденциальности.docx');
        //         document.body.appendChild(link);
        //         link.click();
        //     })
        //     .catch(error => {
        //         this.$refs.toastRef.showCustomToast('Ошибка скачивания файла', 'danger');
        //     });
        // },
		closeResetPasswordModal() {
			this.resetPasswordModal = false;
			this.formResetPassword.email = '';
		},
		async emitCloseEvent() {
			this.$emit('close-auth');
			// Очистка форм после закрытия модального окна с задержкой в 1 секунду
			await new Promise(resolve => setTimeout(resolve, 1000));
			this.resetForm();
			// Остановка таймера
			clearInterval(this.timer);
		},
		// Очистка форм авторизации и регистрации а также скрытие алертов об ошибках и успехе
		resetForm() {
			this.formAuth.email = '';
			this.formAuth.password = '';
			this.formReg.name = '';
			this.formReg.phoneNumber = '';
			this.formReg.email = '';
			this.formReg.password = '';
			this.isShowDangerAuthAlert = false;
			this.isShowDangerRegAlert = false;
			this.isShowSuccessAuthAlert = false;
			this.isShowSuccessRegAlert = false;
			this.isThereRequestToConfirmMail = false;
		},
		onSubmitAuth(event) {
			event.preventDefault();
			this.modeSubmit = 'auth';
			const path = `${API_URL}/tokens`;
			const base_url = window.location.origin;
			axios.post(path, {}, {
				auth: {
				username: this.formAuth.email,
				password: this.formAuth.password
				},
				headers: { 'Client-Url': base_url }
			})
			.then((response) => {
				// console.log('Успешно:', response);
				// document.cookie = `token=${response.data}; SameSite=Strict`;
				document.cookie = `token=${response.data}; Secure; SameSite=Strict`;
				this.showSuccessAuthAlert();
      			window.location.reload();
			})
			.catch((error) => {
				// console.log('Неуспешно:', error);
				try {
					// Если почта не подтверждена, то отображаем окно с возможностью подтвердить почту
					if (error.response.status === 403) {
						this.isThereRequestToConfirmMail = true;
						// Запуск таймера для повторной отправки письма
						this.startTimer();
					}
					else {
						this.showDangerAuthAlert();
					}
				}
				catch {
					this.showDangerAuthAlert();
				}
			});
		},
		onSubmitReg(event) {
			event.preventDefault();
			this.modeSubmit = 'reg';
			const path = `${API_URL}/customers`;
			const phone = this.formReg.phoneNumber.replace(/^8/, '+7');
			const reg = {
				full_name: this.formReg.name,
				email: this.formReg.email,
				password: this.formReg.password,
				phone: phone
			};
			const base_url = window.location.origin;
			
			axios.post(path, reg, { headers: { 'Client-Url': base_url } })
			.then((response) => {
				this.isThereRequestToConfirmMail = true;
				// Запуск таймера для повторной отправки письма
				this.startTimer();
				// document.cookie = `token=${response.data.token}; SameSite=Strict`; // secure; httpOnly;
				// this.showSuccessRegAlert();
      			// window.location.reload();
			})
			.catch((error) => {
				this.showDangerRegAlert();
			});
		}, 
		onSubmitResetPassword(event) {
			event.preventDefault();
			const base_url = window.location.origin;
			const email = this.formResetPassword.email
			const path = `${API_URL}/users/reset_password/${email}`;
			axios.post(path, null, { headers: { 'Client-Url': base_url } })
			.then((response) => {
				this.$refs.toastRefMain.showCustomToast('Письмо для восстановления пароля было отправлено на Вашу почту', 'success', 7000);
				this.resetPasswordModal = false;
			})
			.catch((error) => {
				this.$refs.toastRef2.showCustomToast('Ошибка отправки письма для восстановления пароля', 'danger', 5000);
			});
		},
		showDangerAuthAlert(delay = 5000) {
			if (!this.isShowDangerAuthAlert) {
				this.isShowDangerAuthAlert = true;
				setTimeout(() => {
				this.isShowDangerAuthAlert = false;
				}, delay);
			}
		},
		showDangerRegAlert(delay = 5000) {
			if (!this.isShowDangerRegAlert) {
				this.isShowDangerRegAlert = true;
				setTimeout(() => {
				this.isShowDangerRegAlert = false;
				}, delay);
			}
		},
		showSuccessAuthAlert(delay = 2000) {
			if (!this.isShowSuccessAuthAlert) {
				this.isShowSuccessAuthAlert = true;
				setTimeout(() => {
				this.isShowSuccessAuthAlert = false;
							this.localShowAuth = false;
				}, delay);
			}
		},
		showSuccessRegAlert(delay = 2000) {
			if (!this.isShowSuccessRegAlert) {
				this.isShowSuccessRegAlert = true;
				setTimeout(() => {
				this.isShowSuccessRegAlert = false;
							this.localShowAuth = false;
				}, delay);
			}
		},
	},
};
</script> 

<style scoped>
.password-reset-button {
	color: #2d81e0;
	cursor: pointer;
	text-decoration: none;
	margin: 0;
	padding: 0;
	display: inline-block;
	border: 0;
	background: transparent;
}

.password-reset-button:hover {
	text-decoration: underline;
}
</style>