<template>
    <div  
        v-if="isShowCustomToast" 
        class="toast-custom" 
        :style="{ backgroundColor: customToastBackgroundColor }">
        {{ customToastMessage }}
    </div>
</template>
<script>


export default {
    name: "CustomToastView",
	data() {
		return {
            isShowCustomToast: false,
			customToastMessage: 'Оплата прошла успешно!',
			customToastVariant: 'success', // 'success', 'danger', 'warning', 'info
		};
	},
    computed: {
        customToastBackgroundColor() {
            switch (this.customToastVariant) {
                case "success":
                return "#28a745dd";
                case "warning":
                return "#ffc107dd";
                case "danger":
                return "#dc3545dd"; 
                case "info":
                return "#17a2b8dd";
                default:
                return "#17a2b8dd";
            }
        }
    },
    methods: {
        showCustomToast(message, type = "info", delay = 3000) {
			this.isShowCustomToast = true;
			this.customToastMessage = message;
			this.customToastVariant = type;
			setTimeout(() => {
					this.isShowCustomToast = false;
			}, delay);
		},
	},
};
</script> 

<style scroped>
.toast-custom {
  transition: opacity 0.3s ease-in-out; 
  position: fixed; 
  top: 0; 
  left: 50%; 
  margin-top: 15px; 
  background-color: rgb(31, 172, 106, 0.9); 
  transform: translateX(-50%); 
  color: #fff; 
  padding: 10px; 
  border-radius: 5px; 
  z-index: 9999;
}
</style>