import axios from 'axios';
import { API_URL } from '../../config.js';

export function isPartner() {
    return getUserData().then(data => {
        if (data) {
            // console.log("isPartner: Получены данные пользователя:", data);
            return data.role === 'partner';
        } else {
            // console.log("isPartner: Не удалось получить данные пользователя.");
            return false
        }
    }).catch(error => {
        // console.log("isPartner: Не удалось получить данные пользователя:", error);
        return false
    });
}

export function isAuthenticated() {
    return getUserData().then(data => {
        if (data) {
            // console.log("isAuthenticated: Получены данные пользователя:", data);
            return true
        } else {
            // console.log("isAuthenticated: Не удалось получить данные пользователя.");
            return false
        }
    }).catch(error => {
        // console.log("isAuthenticated: Не удалось получить данные пользователя:", error);
        return false
    });
}

export function getUserData() {
    const path = `${API_URL}/users/me`;
    const token = getCookie("token");
    if (!token) {
        return Promise.reject(new Error("Authorization token is missing"));
    }
    return getAjaxLogged(path, response => {
        // console.log('getUserData:', response.data);
        return response.data;
    }, error => {
        // console.log('getUserData:', error);
        return null;
    });
}

export function updatePartnerData(data) {
    const path = `${API_URL}/partners`;
    return putAjaxLogged(path, data, response => {
        // console.log('putAjaxLogged:', response.data);
        return true;
    }, error => {
        // console.log('putAjaxLogged error:', error);
        return false;
    });
}


export function updateUserData(data) {
    const path = `${API_URL}/users/me`;
    return putAjaxLogged(path, data, response => {
        // console.log('updateUserData:', response.data);
        return true;
    }, error => {
        // console.log('updateUserData:', error);
        return false;
    });
}
 

export function getAjaxLogged(path, t, c) {
    const token = getCookie("token");
    return axios.get(path, { headers: { Authorization: `Bearer ${token}` } }).then(t).catch(c);
}

function putAjaxLogged(path, data, t, c) {
    const token = getCookie("token");
    return axios.put(path, data, { headers: { Authorization: `Bearer ${token}` } }).then(t).catch(c);
}

export function postAjaxLogged(path, data, t, c) {
    const token = getCookie("token");
    return axios.post(path, data, { headers: { Authorization: `Bearer ${token}` } }).then(t).catch(c);
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}