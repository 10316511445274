<template>
    <footer class="bg-light text-center text-lg-star rounded-footer">
        <section class="">
            <div class="container text-center text-md-start mt-3 p-3">
                <div class="row mt-3">
                    <div class="col-md-3 col-lg-4 col-xl-4 mx-auto">
                        <h6 class="fw-bold mb-4">
                            <!-- <i class="fas fa-box-open me-3 text-secondary"></i> -->
                            <!-- ВторойБалкон -->
                            <b-img  fluid src="/favicon.ico" alt="ВторойБалкон" style="max-width: 32px;"></b-img>
                            
                            <p style="font-size: 20px; font-weight: bold; display: inline; margin-left:5px;">ВторойБалкон</p>
                        </h6>
                        <p>
                            Удобный сервис временного хранения вещей в ПВЗ, транспортных компаниях и предприятиях с неиспользуемыми складскими площадями. 
                            Без аренды дальних складов - просто храните свои вещи у нас.
                        </p>
                    </div>

                    <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                        <h6 class="text-uppercase fw-bold mb-4">Информация</h6>
                        <p>
                            <a href="/terms-of-service" style="cursor: pointer;" class="text-reset">Пользовательское соглашение и политика конфиденциальности</a>
                        </p>
                    </div>

                    <div class="col-md-4 col-lg-3 col-xl-4 mx-auto mb-md-0 mb-4">
                        <h6 class="text-uppercase fw-bold mb-4">Контакты</h6>
                        <p><i class="fas fa-home me-3 text-secondary"></i> г. Тюмень, ул. Сургутская д. 11, корп. 2 </p>
                        <p>
                            <i class="fas fa-envelope me-3 text-secondary"></i>
                            01@2balkon.ru
                        </p>
                        <p><i class="fas fa-phone me-3 text-secondary"></i> +7 (922) 000-45-30</p>
                    </div>
                </div>
            </div>
        </section>
        <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.1);">
            © 2023 Все права защищены: 
            <a class="text-dark" href="/">ВторойБалкон.рф</a><br>
            <p style="margin-bottom: 0px; margin-top: 5px;">
                ООО «Бизнес Маркет» <br>
                ИНН 7203487431 <br>
                ОГРН 1197232021843
            </p>
        </div>
        
        <ToastView ref="toastRef" />
    </footer>
</template>

<script>
import ToastView from "./ToastView.vue";

export default {
    components: {
        ToastView,
    },
}
</script>

<style scoped>
.rounded-footer {
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px;
}
</style>