// import 'mdb-vue-ui-kit/css/mdb.min.css'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Multiselect from 'vue-multiselect'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import 'vue-multiselect/dist/vue-multiselect.min.css'


// import "mdb-vue-ui-kit/css/mdb.min.css"; // Стили из mdb-vue-ui-kit
// import { MdbBtn } from "mdb-vue-ui-kit"; // Импортируйте компоненты
// Vue.component("mdb-btn", MdbBtn);

Vue.use(BootstrapVue); 
Vue.use(IconsPlugin);
Vue.component('multiselect', Multiselect)
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
