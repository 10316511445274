import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import YmapPlugin from 'vue-yandex-maps'
import { isAuthenticated, isPartner } from '../utils/user.js';

Vue.use(VueRouter);


const settings = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}

Vue.use(YmapPlugin, settings)

// Убираем предупреждение о неправильном API-ключе (зачем-то он в settings должен быть, хотя все запросы на сервере)
console.warn = function(message, ...rest) {
  if (message.includes("Invalid API key")) {
    return;
  }
  originalWarn.call(this, message, ...rest);
}


const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/partnership",
    name: "Partnership",
    component: () => import("../views/PartnershipView.vue"),
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("../views/SupportView.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../views/ContactsView.vue"),
  },
  {
    path: "/map",
    name: "Map",
    component: () => import("../views/MapView.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/UserAccountView.vue"),
    beforeEnter: (to, from, next) => {
      // Получаем токен из адреса. Данный токен может прийти в адресе при перенаправлении с почты
      const token = to.query.token;
      if (token) {
        // document.cookie = `token=${token}; SameSite=Strict`;
        document.cookie = `token=${token}; Secure; SameSite=Strict`;
      }
      // Проверяем что пользователь залогинен
      isAuthenticated().then(isAuth => {
        if (isAuth) {
          // Проверяем что пользователь партнер
          isPartner().then(isPart => {
            if (isPart) {
              if (token) {
                next('/account-partner?tab=payment_settings'); // перенаправляем на страницу партнера с активной вкладкой настройки оплаты
              }
              else {
                next('/account-partner'); // перенаправляем на страницу партнера
              }
            }
            else {
              // Если токен есть в запросе, то перенаправляем на страницу пользователя без токена в адресе
              if (token) {
                next('/account?newAccount=true');
              } 
              // Продолжаем навигацию к странице пользователя 
              else {
                next();
              }
            }
          }).catch(error => { next('/') }); // перенаправляем на главную страницу
        } else {
          next('/'); // перенаправляем на главную страницу
        }
      }).catch(error => { next('/') }); // перенаправляем на главную страницу
    }
  },
  {
    path: "/account-partner",
    name: "AccountPartner",
    component: () => import("../views/PartnerAccountView.vue"),
    beforeEnter: (to, from, next) => {
      // Проверяем что пользователь залогинен
      isAuthenticated().then(isAuth => {
        if (isAuth) {
          // Проверяем что пользователь партнер
          isPartner().then(isPart => {
            if (isPart) {
              next(); // продолжаем навигацию к странице партнера
            }
            else {
              next('/account'); // перенаправляем на страницу пользователя
            }
          }).catch(error => { next('/') }); // перенаправляем на главную страницу
        } else {
          next('/'); // перенаправляем на главную страницу
        }
      }).catch(error => { next('/') }); // перенаправляем на главную страницу
    }
  },
  {
    path: "/offer-of-cooperation",
    name: "OfferOfCooperation",
    component: () => import("../views/OfferOfCooperationView.vue"),
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: () => import("../views/TermsOfServiceView.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPasswordView.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
