<template>
    <div class="container mt-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-8">
                <b-card class="p-3 pt-4 paddingbottom0">
                    <h2 class="text-center">Сервис временного хранения вещей</h2>
                    <h5 class="text-center">Найти ближайший пункт</h5>
                    <b-input-group class="mt-4">
                        <b-form-input
                            v-model="address"
                            placeholder="Введите адрес, например, город, район или улицу"
                            @input="onInput"
							list="address-suggestions"
							id="address-input"
                        >
                        </b-form-input>
                        <b-form-datalist id="address-suggestions" :options="uniqueSuggestions"></b-form-datalist>
                        <b-button variant="success" class="text-capitalize" @click="onFind">
                            <b-icon-search></b-icon-search> Найти
                        </b-button>
                    </b-input-group>
                    <div id="parent_filters" class="d-flex align-items-center mb-3 pt-2">
                        <p class="text-nowrap mb-0" style="margin-right: 10px;">Тип вещи:</p>
                        <multiselect
                            class="flex-grow-1"
                            v-model="filter.selected_types_things"
                            :track-by="'id'"
                            :multiple="true"
                            :options="types_things"
                            selectLabel="Выбрать"
                            deselectLabel="Убрать"
                            selectedLabel="Выбрано"
                            placeholder="Выберите, что хотите оставить на хранение"
                            :custom-label="option => option.name"
                            :close-on-select="false"
                            :max-height="150"
                            style="max-width: 100%;"
                        >
                        <template v-slot:noResult><span>Нет результатов</span></template>
                        <template v-slot:noOptions>
                          <span>Список пуст</span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="d-flex flex-wrap justify-between mt-3">
                        <p class="d-flex align-items-center flex-fill me-4 marginbottom0">
                            <i class="fas fa-shield-alt text-secondary me-2"></i>
                            Гарантия от ущерба 50 000₽
                        </p>
                        <p class="d-flex align-items-center flex-fill me-4 marginbottom0">
                            <i class="fas fa-lock text-secondary me-2"></i>
                            Безопасное хранение
                        </p>
                        <p class="d-flex align-items-center flex-fill marginbottom0">
                            <i class="fas fa-undo-alt text-secondary me-2"></i>
                            Возврат средств при отмене
                        </p>
                    </div>
                </b-card>
                <br/>
                <b-container fluid class="p-4 mb-4">
                    <h3 class="text-center">Хранение багажа стало проще</h3>
                    <b-row class="d-flex justify-content-center mt-4">
                        <b-col class="text-center">
                            <b-img  fluid src="/images/Место хранения.png" alt="Место хранения" class="mb-3" style="max-width: 150px;"></b-img>
                            <h5>Забронируйте удобное место для хранения</h5>
                            <p>
                                Выберите одну из ближайших локаций хранения и забронируйте онлайн в удобное для Вас время
                            </p>
                        </b-col>
                        <b-col class="text-center">
                            <b-img  fluid src="/images/Багаж.png" alt="Багаж" class="mb-3" style="max-width: 150px;"></b-img>
                            <h5>Придите и оставьте багаж</h5>
                            <p>
                                Придите в выбранную локацию и принесите свой багаж.
                                Каждая вещь будет тщательно опечатана и убрана на хранение
                            </p>
                        </b-col>
                        <b-col class="text-center">
                            <b-img fluid src="/images/Отдых.png" alt="Отдых" class="mb-3" style="max-width: 150px;"></b-img>
                            <h5>Проводите время без багажа</h5>
                            <p>
                                Освободите себя от лишней нагрузки! Наслаждайтесь путешествием или деловой поездкой с безопасным хранением багажа
                            </p>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div> 
        <ToastView ref="toastRef" />
    </div>
</template>

<script>
import axios from "axios";
import { API_URL } from '../../config.js';
import ToastView from "./ToastView.vue";

export default {
  components: {
    ToastView,
  },
  data() {
    return {
	  types_things: [],
      filter: {
        selected_types_things: [],
      },
      address: "",
      suggestions: [],
    };
  },
  mounted() {
		  this.checkStatus();
	},
  methods: {
    checkStatus() {
      const email_confirmation_failed = this.$route.query.email_confirmation_failed;
      const reset_password_failed = this.$route.query.reset_password_failed;
      const reset_password_success = this.$route.query.reset_password_success;
			if (email_confirmation_failed === 'true') {
				this.$router.push({ path: '/' }); // изменить ссылку в адресной строке на /account-partner
				this.$refs.toastRef.showCustomToast('Ссылка для подтверждения почты устарела', 'danger', 7000);
			}
      else if (reset_password_failed === 'true') {
        this.$router.push({ path: '/' });
        this.$refs.toastRef.showCustomToast('Ссылка для сброса пароля устарела или неверна', 'danger', 7000);
      }
      else if (reset_password_success === 'true') {
        this.$router.push({ path: '/' });
        this.$refs.toastRef.showCustomToast('Пароль успешно изменен. Вы можете войти в систему', 'success', 7000);
      }

      
    },
    onInput(value) {
      if (value.length < 3) {
        return;
      }

      // Получаем полный адрес по координатам 
      const path = `${API_URL}/get-suggestions-by-address`;
      const data = {
          address: value
      };
      axios.post(path, data).then((res) => {
          this.suggestions = res.data;
          // console.log("Подсказки", this.suggestions);
      }).catch((error) => {
          // console.error(error);
      })
    },
    onFind() {
        if (!this.address) {
            this.address = "Тюмень";
        }
        // Удалить выделенный объект из локального хранилища (чтобы при переходе на страницу карты не было выделенного объекта)
        try { localStorage.removeItem("selectedObjectID"); } catch (error) { } 
        this.$router.push({
            path: "/map",
            query: { address: this.address, types_things: this.filter.selected_types_things.map((item) => item.id) },
        });
    },
    // Получить типы вещей
    getTypesThings() {
      const path = `${API_URL}/item_types`;
      axios.get(path)
      .then((res) => {
        this.types_things = res.data;
      }).catch ((err) => {
        console.error(err);
      })
    },
  },
  computed: {
    uniqueSuggestions() {
      return Array.from(new Set(this.suggestions));
    },
  },
  created() {
    this.getTypesThings();
  }
};
</script>

<style scoped>
/* 
.btn-secondary, .btn-secondary:focus, .btn-secondary:active {
    color: #fff;
    background-color: #00838f !important;
    border-color: #00838f !important;
    box-shadow: none;
} */

.paddingbottom0 {
    padding-bottom: 0 !important;
}

.marginbottom0 {
    margin-bottom: 3px;
}

.form-control:focus {
    box-shadow: none;
    border: 1px solid #00838f;
}

@media (max-width: 767px) {
  #parent_filters {
    flex-direction: column;
  }
}


</style>