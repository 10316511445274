<template>
  <div class="home container">
    <b-container class="d-flex flex-column min-vh-100">
      <Header />
      <b-row class="flex-grow-1">
        <b-col>
          <Main />
        </b-col>
      </b-row>
      <Footer />
    </b-container>
  </div>
</template>

<script>
import Header from "@/components/HeaderView.vue";
import Main from "@/components/HomeMain.vue";
import Footer from "@/components/FooterView.vue";
export default {
  name: "HomeView",
  components: {
    Header, Main, Footer
  },
};
</script>
