<template>
  <header class="sticky-header">
    <div class="container-fluid bg-light rounded-header">
      <b-navbar class="desktop-menu-container" toggleable="lg" type="light" >
        <b-navbar-brand href="/">
          <b-img  fluid src="/favicon.ico" alt="ВторойБалкон" style="max-width: 32px;"></b-img>
				  <p style="font-weight: bold; display: inline; margin-left:5px;">ВторойБалкон</p>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>   <!-- <b-sidebar></b-sidebar> -->
          <!-- Навигационные элементы, выровненные по центру  -->
          <b-navbar-nav class="me-auto mb-2 mb-lg-0 nav-centered">
            <b-nav>
              <b-nav-item>
                <b-button variant="light" @click="goToMap">Карта пунктов</b-button>
              </b-nav-item>
              <b-nav-item href="/about">
                <b-button variant="light">О сервисе</b-button>
              </b-nav-item>
              <b-nav-item href="/partnership" class="disabled">
                <b-button variant="light">Стать партнёром</b-button>
              </b-nav-item>
              <b-nav-item href="/support">
                <b-button variant="light">Поддержка</b-button>
              </b-nav-item>
              <b-nav-item href="/contacts">
                <b-button variant="light">Контакты</b-button>
              </b-nav-item>
            </b-nav>
          </b-navbar-nav>
          <!-- Навигационные элементы, выровненные по правому краю  -->
          <b-navbar-nav class="ml-auto" >
            <div v-if="isUserDataLoading" class="text-center text-primary my-2">
              <b-spinner class="align-middle" style="margin-right: 5px;"></b-spinner>
            </div>
            <div v-else>
              <b-button v-if="!isAuthenticated()" variant="primary" @click="showAuth = true">Войти</b-button>
              <b-dropdown
                v-else
                :text="truncate(user.full_name, 25)"
                block
                variant="outline-primary"
                menu-class="w-100"
              >
                <b-dropdown-item href="/account">
                  <i class="fas fa-user" style="margin-right: 4px;"></i>Профиль
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="#" @click="logout">
                  <i class="fas fa-sign-out-alt" ></i> Выйти
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <div class="mobile-menu-container">
        <b-button class="mobile-menu-button rounded-circle" v-b-toggle.sidebar-backdrop>
          <i class="fa fa-bars"></i>
        </b-button>
        <b-sidebar id="sidebar-backdrop" 
                   shadow 
                   no-header 
                   backdrop-variant="dark"
                   backdrop
        >
          <template #footer="{ hide }">
            <div class="d-flex  text-light align-items-center px-3 py-2">
              <!-- <b-button size="sm" @click="hide">Закрыть</b-button> -->
              
              <div v-if="isUserDataLoading" class="text-center text-primary my-2">
                <b-spinner class="align-middle" style="margin-right: 5px;"></b-spinner>
              </div>
              <div v-else>
                <b-button v-if="!isAuthenticated()" variant="primary" class="w-100" @click="showAuth = true">Войти</b-button>
                <b-dropdown
                  v-else
                  :text="truncate(user.full_name, 25)"
                  block
                  variant="outline-primary"
                  menu-class="w-100"
                >
                  <b-dropdown-item href="/account">
                    <i class="fas fa-user" style="margin-right: 4px;"></i>Профиль
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="#" @click="logout">
                    <i class="fas fa-sign-out-alt" ></i> Выйти
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <div class="p-3">
            <a id="sidebar-title" href="/">
              <b-img  fluid src="/favicon.ico" alt="ВторойБалкон" style="max-width: 32px;"></b-img>
              ВторойБалкон
            </a>
            <hr style="margin-left: 8px;margin-right: 8px; display: block; height: 1px; background:#0000001f" aria-orientation="horizontal">
            
            
            <b-nav vertical id="menu-sidebar" class="pl-0">
              <b-nav-item>
                <b-button variant="light" @click="goToMap">Карта пунктов</b-button>
              </b-nav-item>
              <b-nav-item href="/about">
                <b-button variant="light" class="w-100 text-start">О сервисе</b-button>
              </b-nav-item>
              <b-nav-item href="/partnership" >
                <b-button variant="light" class="w-100 text-start">Стать партнёром</b-button>
              </b-nav-item>
              <b-nav-item href="/support">
                <b-button variant="light" class="w-100 text-start">Поддержка</b-button>
              </b-nav-item>
              <b-nav-item href="/contacts">
                <b-button variant="light" class="w-100 text-start">Контакты</b-button>
              </b-nav-item>
              <!-- <b-nav-item href="#" class="mt-3 text-center">
                <b-button variant="primary" class="w-100">Войти</b-button>
              </b-nav-item> -->
            </b-nav> 
          </div>
          
        </b-sidebar>
      </div> 
    </div>
	  <!-- Форма с авторизацией/регистрацией -->
    <AuthView :show-auth="showAuth" @close-auth="showAuth = false"   /> <!-- v-bind:show-auth= -->
  </header>
</template>

<script>
import AuthView from './AuthView.vue'; 
import { getUserData } from '../utils/user.js';
import { API_URL } from '../../config.js';
import axios from 'axios';

export default {
  components: {
    AuthView,
  },
  data() {
    return {
      showAuth: false,
      user: {},
      isUserDataLoading: true,
    };
  },
  created() {
    this.getUserData(); // setTimeout(() => { this.getUserData(); }, 2000);
  },
  methods: {
    // Обрезает строку до указанной длины и добавляет многоточие
    truncate(value, length) {
      if (value.length > length) {
        return value.substring(0, length) + '...';
      }
      return value;
    },
    // Перейти на страницу с картой по текущему адресу пользователя, 
		// если не удалось определить адрес, то переходит на страницу с картой по адресу "Тюмень"
    async goToMap() {
      let userAddress = 'Тюмень';
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const path = `${API_URL}/get-address-by-coords`;
        const data = {
          longitude: longitude,
          latitude: latitude,
        };
        const res = await axios.post(path, data);
        userAddress = res.data;
      } catch (error) {
        // console.error('Ошибка: ' + error);
      }
      // Если текущая страница является страницей карты, то при переходе обновляем страницу
      if (this.$route.path === '/map') {
        // Защита от избыточной переадресации. Проверка на то, что в адресе не указано местоположение, на которое собираемся перейти
        if (this.$route.query.address !== userAddress) {
          this.$router.push(`/map?address=${userAddress}`);
        }
        // Если адрес новый или если тот же, в любом случае перезагружаем страницу (иначе в мобильной версии боковая панель не закрывается автоматически после нажатия)
        location.reload();
      }
      else {
        // Удалить выделенный объект из локального хранилища (чтобы при переходе на страницу карты не было выделенного объекта)
        try { localStorage.removeItem("selectedObjectID"); } catch (error) { } 
        this.$router.push(`/map?address=${userAddress}`);
      }
    },

    // Получаем данные о пользователе с сервера
    getUserData() {
      getUserData().then(data => {
        if (data) {
					this.user = data;
        } else {
					this.clearUserData();
        }
			}).catch(error => {
        this.clearUserData();
			}).finally(() => {
          this.isUserDataLoading = false;
        });
    },
    isAuthenticated() {
      return Object.keys(this.user).length !== 0;
    },
    logout() {
      // Удаляем токен из куки и перезагружаем страницу
      this.clearUserData();
      window.location.reload();
    },
    clearUserData() {
      this.user = {};
      document.cookie = 'token=;';
    }
  }
};
</script>

<style scoped>
/* .user-dropdown {
  background-color: transparent;
}

.user-dropdown:hove r {
  background-color: lightgray;
}

.user-dropdown:active {
  background-color: rgb(200, 200, 200);
} */



.form-control:focus {
    box-shadow: none;
    border: 1px solid #00838f;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 151;
}

[data-v-5730e402] a {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-left: 10px;
}

.nav-centered {
  width: 100%;
  justify-content: center;
}

.rounded-header {
  /* border-radius: 10px; */
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 10px;
}

.mobile-menu-button {
  z-index: 148;
}


@media (max-width: 1024px) {
  .desktop-menu-container {
    display: none;
  }
  .mobile-menu-container {
    position: relative;
  }

  .mobile-menu-button {
  position: fixed;
  top: 8px;
  right: 8px;
  width: 40px; 
  height: 40px; 
  border: none;
  /* background-color: transparent; */
  cursor: pointer;

  display: flex; 
  justify-content: center; 
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

}

  /* .mobile-menu-button {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  } */
  .mobile-menu-button i {
    font-size: 24px;
  }
  #sidebar-backdrop {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  #sidebar.show {
    transform: translateX(0);
  }
}

@media (min-width: 1025px) {
  .mobile-menu-container {
    display: none;
  }
  .desktop-menu-container {
    position: relative;
  }
}

#sidebar-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  margin-bottom: 16px;
  display: block;
}
</style>
